<template>
  <div class="app">

    <div class="title-struct">
      <div class="cm-title">
        <h4>MS CUQY CALVO MATEOS</h4><p>(Legal Name)</p>
      </div>
      <div class="urgency-mention">
        <p>Urgency: 10</p>
      </div>
    </div>

    <div class="divider"></div>

    <div class="header-info">

      <div class="first header-info-part">
        <img alt="card-illus" class="card-image" src="../assets/img/gold.png">
        <p class="mention">Member Since</p>
        <div class="mini-divider"></div>
      </div>

      <div class="second header-info-part">
        <p class="info-size">Carte AIR FRANCE KLM -</p>
        <p class="info-size">AMERICAN EXPRESS SILVER</p>
        <br>
        <p class="info-size">AENB</p>
        <p class="info-size">MS CUQY CALVO MATEOS</p>
        <p class="mention">(Name on Card)</p>
        <p class="info-size">20222416900507022</p>
      </div>

      <div class="third header-info-part struct-with-line">
        <p class="mention">Address</p>
        <p class="info-size">55 Rue Monsenor Riberi</p>
        <p class="info-size">CORIA, 67349</p>
      </div>

      <div class="fourth header-info-part struct-with-line">
        <p class="mention">Phone</p>
        <div class="phone-cat phone-cat-one">
          <div class="mini-divider"></div><p class="info-size">(H)</p>
        </div>
        <div class="phone-cat phone-cat-two">
          <p class="info-size" style="margin-top: 5px">0738393837</p><p class="info-size">(M)</p>
        </div>
        <div class="phone-cat phone-cat-three">
          <div class="mini-divider"></div><p class="info-size">(B)</p>
        </div>
      </div>

      <div class="fifth header-info-part struct-with-line">
        <p class="mention">Date of Birth</p>
        <p class="info-size">03-Aug-2005</p>
        <p class="mention" style="margin-top: 10px">Place of Birth</p>
        <p class="info-size">MALTE</p>
      </div>

    </div>

    <div class="progress-bar">

      <div class="progress-step-wrapper">
        <svg class="progress-step" :class="{ 'progress-step-style-one' : styles.styleOne, 'progress-step-style-two' : styles.styleTwo}">
          <polygon points="0,0 0,30 140,30 150,15 140,0 0,0"/>
        </svg>
        <div class="progress-step-text">
          <p>Capture App</p>
        </div>
      </div>

      <div class="progress-step-wrapper progress-step-offset">
        <svg class="progress-step" :class="{ 'progress-step-style-one' : styles.styleOne, 'progress-step-style-two' : styles.styleTwo}">
          <polygon points="0,0 10,15 0,30 140,30 150,15 140,0 0,0"/>
        </svg>
        <div class="progress-step-text">
          <p>Internal Checks</p>
        </div>
      </div>

      <div class="progress-step-wrapper progress-step-offset">
        <svg class="progress-step" :class="{ 'progress-step-style-one' : styles.styleOne, 'progress-step-style-two' : styles.styleTwo}">
          <polygon points="0,0 10,15 0,30 140,30 150,15 140,0 0,0"/>
        </svg>
        <div class="progress-step-text">
          <p>External Data Pull</p>
        </div>
      </div>

      <div class="progress-step-wrapper progress-step-offset">
        <svg class="progress-step" :class="{ 'progress-step-style-one' : styles.styleOne, 'progress-step-style-two' : styles.styleTwo}">
          <polygon points="0,0 10,15 0,30 140,30 150,15 140,0 0,0"/>
        </svg>
        <div class="progress-step-text">
          <p>Risk Evaluation</p>
        </div>
      </div>

      <div class="progress-step-wrapper progress-step-offset">
        <svg class="progress-step" :class="{ 'progress-step-style-one' : styles.styleOne, 'progress-step-style-two' : styles.styleTwo}">
          <polygon points="0,0 10,15 0,30 140,30 150,15 140,0 0,0"/>
        </svg>
        <div class="progress-step-text">
          <p>Compliance Verification</p>
        </div>
      </div>

      <div class="progress-step-wrapper progress-step-offset">
        <svg class="progress-step" :class="{ 'progress-step-style-one' : styles.styleOne, 'progress-step-style-two' : styles.styleTwo}">
          <polygon points="0,0 10,15 0,30 140,30 150,15 140,0 0,0"/>
        </svg>
        <div class="progress-step-text">
          <p>Final Decision</p>
        </div>
      </div>

      <div class="progress-step-wrapper progress-step-offset">
        <svg class="progress-step" :class="{ 'progress-step-style-one' : styles.styleOne, 'progress-step-style-two' : styles.styleTwo}">
          <polygon points="0,0 10,15 0,30 140,30 150,15 140,0 0,0"/>
        </svg>
        <div class="progress-step-text">
          <p>Fulfillment</p>
        </div>
      </div>

      <div class="progress-step-wrapper progress-step-offset">
        <svg class="progress-step" :class="{ 'progress-step-style-one' : styles.styleOne, 'progress-step-style-two' : styles.styleTwo}">
          <polygon points="0,0 10,15 0,30 150,30 150,0 0,0"/>
        </svg>
        <div class="progress-step-text">
          <p>Finalize</p>
        </div>
      </div>

    </div>

    <div class="status-bar">
      <svg class="status-bar-background" height="90px" width="100%">
        <defs>
          <linearGradient id="three_opacity_stops">
            <stop offset="0%" style="stop-color: #0d9900; stop-opacity: 1.0"/>
            <stop offset="50%" style="stop-color: #0d9900; stop-opacity: 0.3"/>
            <stop offset="100%" style="stop-color: #0d9900; stop-opacity: 0"/>
          </linearGradient>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" style="fill: url(#three_opacity_stops);"/>
      </svg>
      <h3 class="status-bar-text">Approved</h3>
    </div>

    <div class="top-button-section">
      <div class="top-buttons">
        <div class="light-top-button" :class="{ 'light-top-button-style-one' : styles.styleOne, 'light-top-button-style-two' : styles.styleTwo}" @click="showNoteEditor">
          <p>Add Note</p>
        </div>
        <div class="light-top-button" :class="{ 'light-top-button-style-one' : styles.styleOne, 'light-top-button-style-two' : styles.styleTwo}" @click="showPreMenu">
          <p>Actions</p>
        </div>
        <div class="dark-top-button">
          <p>Close</p>
        </div>
      </div>

      <Transition>
      <div class="action-premenu" v-show="premenuShowing">
        <div class="action-menu-option" @mouseover="hideRemainingOptions">
          <p class="action-menu-title">Refresh</p>
        </div>
        <div class="action-menu-option" @mouseover="showRemainingOptions" @mouseleave="hideRemainingOptions">
          <p class="action-menu-title">Route to WB: ></p>
        </div>
      </div>
      </Transition>

      <Transition>
        <div class="remaining-option-menu" v-show="remaingOptionsShowing && premenuShowing" @mouseleave="hideRemainingOptions" @mouseover="showRemainingOptions">
          <div class="action-menu-option"  style="border-bottom: 1px solid white;">
            <p class="action-menu-title">Resume (fixed) problem fl...</p>
          </div>
          <div class="action-menu-option">
            <p class="action-menu-title">Update Basic Applicant</p>
          </div>
          <div class="action-menu-option">
            <p class="action-menu-title">Add/Update Additional App</p>
          </div>
          <div class="action-menu-option">
            <p class="action-menu-title">Conditions To Satisfy</p>
          </div>
          <div class="action-menu-option">
            <p class="action-menu-title">Send For Decision</p>
          </div>
          <div class="action-menu-option">
            <p class="action-menu-title">Reroute Application</p>
          </div>
          <div class="action-menu-option">
            <p class="action-menu-title">Manual Bureau Actions</p>
          </div>
          <div class="action-menu-option">
            <p class="action-menu-title">Exception Processing</p>
          </div>
          <div class="action-menu-option">
            <p class="action-menu-title">ServiceDataErrorCorrection</p>
          </div>
          <div class="action-menu-option">
            <p class="action-menu-title">Technical WB Actions</p>
          </div>
          <div class="action-menu-option">
            <p class="action-menu-title">GetSubLegalEntity Error</p>
          </div>
        </div>
      </Transition>

    </div>

    <div class="main-app-content">

      <div class="control-section">
        <div class="tab-control" :class="{ 'tab-active' : catShowing.overviewShowing, 'tab-active-style-one': catShowing.overviewShowing && styles.styleOne, 'tab-active-style-two': catShowing.overviewShowing && styles.styleTwo, 'tab-inactive' : !catShowing.overviewShowing}" @click="changeCategoryShowing('showOverview')">
          <p>Overview</p>
        </div>

        <div class="tab-control" :class="{ 'tab-active' : catShowing.applicantShowing, 'tab-active-style-one': catShowing.applicantShowing && styles.styleOne, 'tab-active-style-two': catShowing.applicantShowing && styles.styleTwo,  'tab-inactive' : !catShowing.applicantShowing}" @click="changeCategoryShowing('showApplicant')">
          <p>Applicant</p>
        </div>

        <div class="tab-control" :class="{ 'tab-active' : catShowing.creditShowing, 'tab-active-style-one': catShowing.creditShowing && styles.styleOne, 'tab-active-style-two': catShowing.creditShowing && styles.styleTwo,  'tab-inactive' : !catShowing.creditShowing}" @click="changeCategoryShowing('showCredit')">
          <p>Credit</p>
        </div>

        <div class="tab-control" :class="{ 'tab-active' : catShowing.suspenseChecksShowing, 'tab-active-style-one': catShowing.suspenseChecksShowing && styles.styleOne, 'tab-active-style-two': catShowing.suspenseChecksShowing && styles.styleTwo,  'tab-inactive' : !catShowing.suspenseChecksShowing}" @click="changeCategoryShowing('showSuspenseCheck')">
          <p>Suspense Checks</p>
        </div>

        <div class="tab-control" :class="{ 'tab-active' : catShowing.CBOShowing, 'tab-active-style-one': catShowing.CBOShowing && styles.styleOne, 'tab-active-style-two': catShowing.CBOShowing && styles.styleTwo,  'tab-inactive' : !catShowing.CBOShowing}" @click="changeCategoryShowing('showCBO')">
          <p>CBO</p>
        </div>

        <div class="tab-control" :class="{ 'tab-active' : catShowing.notesShowing, 'tab-active-style-one': catShowing.notesShowing && styles.styleOne, 'tab-active-style-two': catShowing.notesShowing && styles.styleTwo,  'tab-inactive' : !catShowing.notesShowing}" @click="changeCategoryShowing('showNotes')">
          <p>Notes</p>
        </div>

        <div class="tab-control" :class="{ 'tab-active' : catShowing.communicationsShowing, 'tab-active-style-one': catShowing.communicationsShowing && styles.styleOne, 'tab-active-style-two': catShowing.communicationsShowing && styles.styleTwo,  'tab-inactive' : !catShowing.communicationsShowing}" @click="changeCategoryShowing('showCommunications')">
          <p>Communications</p>
        </div>

        <div class="tab-control" :class="{ 'tab-active' : catShowing.referenceDataShowing, 'tab-active-style-one': catShowing.referenceDataShowing && styles.styleOne, 'tab-active-style-two': catShowing.referenceDataShowing && styles.styleTwo,  'tab-inactive' : !catShowing.referenceDataShowing}" @click="changeCategoryShowing('showReferenceData')">
          <p>Reference Data</p>
        </div>

        <div class="tab-control" :class="{ 'tab-active' : catShowing.userTrackingNotesShowing, 'tab-active-style-one': catShowing.userTrackingNotesShowing && styles.styleOne, 'tab-active-style-two': catShowing.userTrackingNotesShowing && styles.styleTwo,  'tab-inactive' : !catShowing.userTrackingNotesShowing}" @click="changeCategoryShowing('showUserTrackingNotes')">
          <p>User Tracking Notes</p>
        </div>

      </div>

      <div class="content-section" ref="contentSection">

        <div class="content-information" ref="overviewContent" v-show="catShowing.overviewShowing">
          <div class="content-info-parts">
            <div class="subcontent-info overview-status" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
              <p>Status</p>
              <p>Pending</p>
            </div>
            <div class="subcontent-info overview-app-details" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
              <p>Application Details</p>
              <div class="app-detail-info-struct grid-four">
                <div class="card-member-type sub-content-struct">
                  <p class="sub-content-label">Card Member Type</p>
                  <p>Prospect</p>
                </div>
                <div class="preselected sub-content-struct">
                  <p class="sub-content-label">Preselected</p>
                  <p></p>
                </div>
                <div class="previous-status sub-content-struct">
                  <p class="sub-content-label">Previous Status</p>
                  <p>Pending</p>
                </div>
                <div class="additional-card-info sub-content-struct">
                  <p class="sub-content-label">Additional Card (#)</p>
                  <p>0</p>
                </div>
                <div class="vendor-info sub-content-struct">
                  <p class="sub-content-label">Vendor</p>
                  <p>INTERNET APPLICATIONS</p>
                </div>
                <div class="channel-info sub-content-struct">
                  <p class="sub-content-label">Channel</p>
                  <p>Member Get Member</p>
                </div>
                <div class="doc-available sub-content-struct">
                  <p class="sub-content-label">Documents Available</p>
                  <p>Y</p>
                </div>
              </div>
            </div>
            <div class="subcontent-info overview-relevant-dates" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
              <p>Relevant Dates</p>
              <div class="app-detail-info-struct grid-three">
                <div class="received-date sub-content-struct">
                  <p class="sub-content-label">Received Date</p>
                  <p>14-Sep-2022</p>
                </div>
                <div class="finalized-date sub-content-struct">
                  <p class="sub-content-label">Finalize Date</p>
                  <p>16-Sep-2022</p>
                </div>
                <div class="last-contact sub-content-struct">
                  <p class="sub-content-label">Last Contact</p>
                  <p>15-Sep-2022</p>
                </div>
                <div class="card-mail-date sub-content-struct">
                  <p class="sub-content-label">Card Mail Date</p>
                  <p></p>
                </div>
                <div class="reactivation-date sub-content-struct">
                  <p class="sub-content-label">Reactivation Date</p>
                  <p></p>
                </div>
              </div>
            </div>
            <div class="subcontent-info overview-cts" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
              <p>Conditions to Satisfy Information</p>
              <div class="cond-group">
                <p class="sub-content-label">Applicant</p>
                <DropdownSelect :current-option="currentOptionActive" :options-available="optionsAvailable" @resize="resizeSection('overview')" @update-selected-value="updateSelectedValue"/>
                <p class="sub-content-label">BEPN</p>
                <p>No Eligible BEPN</p>
              </div>
            </div>
          </div>
        </div>

        <div class="content-information" ref="applicantContent" v-show="catShowing.applicantShowing">

          <div class="applicant-product-information-struct">

            <div class="ac-arrow-struct" @click="showHideElement(this, applicantPartShowing.productInformationShowing, 'showHideProductInformation')">
              <svg id="Layer_1" class="ac-arrow" :class="{ 'ac-arrow-active' : applicantPartShowing.productInformationShowing, 'ac-arrow-not-active' : !applicantPartShowing.productInformationShowing, 'ac-arrow-style-one': styles.styleOne, 'ac-arrow-style-two': styles.styleTwo}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 142.58"><path class="cls-1" d="M283.46,0c-48.14,48.45-96.56,97.17-141.68,142.58C97.21,97.75,48.06,48.32,0,0H283.46Z"/></svg>
              <p class="info-size info-size-title">Product Information</p>
            </div>

            <div class="subcontent-info applicant-info applicant-product-information" ref="productInformationContent" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo, 'applicant-product-information-visible': applicantPartShowing.productInformationShowing}">
              <div class="outside-m">
                <p class="mention">Is this a paper application ?</p>
                <p class="info-size">No</p>
              </div>
              <div class="applicant-product-information-content">
                <p class="info-size">Product Information</p>
                <div class="product-info-line-one product-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Product Type</p>
                    <p>CPS</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Card Product Type</p>
                    <p>CPS Charge</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Card Product</p>
                    <p>Carte AIR FRANCE KLM - AMERICAN EXPRESS GOLD</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Logo</p>
                    <p>832</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">PCT</p>
                    <p>8X4</p>
                  </div>
                </div>
                <div class="product-info-line-two product-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">SPID</p>
                    <p>F33</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Source Code</p>
                    <p>A0000F743V</p>
                  </div>
                </div>
                <div class="product-info-line-three product-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Flying Blue Card/PCAN Number</p>
                    <p>005061845245</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Current Flying Blue Map Level</p>
                    <p>Explorer</p>
                  </div>
                </div>
                <div class="product-info-line-four product-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Received Date</p>
                    <p>04-Sep-2022</p>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div class="applicant-personal-information-struct">

            <div class="ac-arrow-struct" @click="showHideElement(this, applicantPartShowing.personalInformationShowing, 'showHidePersonalInformation')">
              <svg id="Layer_1" class="ac-arrow" :class="{ 'ac-arrow-active' : applicantPartShowing.personalInformationShowing, 'ac-arrow-not-active' : !applicantPartShowing.personalInformationShowing, 'ac-arrow-style-one': styles.styleOne, 'ac-arrow-style-two': styles.styleTwo}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 142.58"><path class="cls-1" d="M283.46,0c-48.14,48.45-96.56,97.17-141.68,142.58C97.21,97.75,48.06,48.32,0,0H283.46Z"/></svg>
              <p class="info-size info-size-title">Personal Information</p>
            </div>

            <div class="subcontent-info applicant-info applicant-personal-information" ref="personalInformationContent">
              <div style="padding: 15px" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
              <p class="info-size">Applicant Information</p>
              <div class="personal-info-line-one personal-info-line">
                <div class="sub-content-struct">
                  <p class="sub-content-label">Prefix</p>
                  <p>Mr</p>
                </div>
              </div>
              <div class="personal-info-line-two personal-info-line">
                <div class="sub-content-struct">
                  <p class="sub-content-label">First Name</p>
                  <p>CUQY</p>
                </div>
                <div class="sub-content-struct">
                  <p class="sub-content-label">Middle Name</p>
                  <p>AVENTA</p>
                </div>
                <div class="sub-content-struct">
                  <p class="sub-content-label">Last Name</p>
                  <p>CALVO MATEOS</p>
                </div>
              </div>
              <div class="personal-info-line-three personal-info-line">
                <div class="sub-content-struct">
                  <p class="sub-content-label">Name on Card</p>
                  <p>CUQY CALVO MATEOS</p>
                </div>
              </div>
              <div class="personal-info-line-four personal-info-line">
                <div class="sub-content-struct">
                  <p class="sub-content-label">Email</p>
                  <p>cuqycm@gmail.com</p>
                </div>
                <div class="sub-content-struct">
                  <p class="sub-content-label">Mother's Maiden Name</p>
                  <p>MATEOS</p>
                </div>
              </div>
            </div>

              <div style="padding: 15px; margin-top: 20px;" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
                <p class="info-size">Phone Numbers</p>
                <div class="phone-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Place</p>
                    <p>___</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Country Code</p>
                    <p>___</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Home</p>
                    <p>___</p>
                  </div>
                </div>
                <div class="phone-info-line-two personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Place</p>
                    <p>Metropolis and Overseas</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Country Code</p>
                    <p>33</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Mobile</p>
                    <p>0638398373</p>
                  </div>
                </div>
                <div class="phone-info-line-three personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Place</p>
                    <p>___</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Country Code</p>
                    <p>___</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Business</p>
                    <p>___</p>
                  </div>
                </div>
              </div>

              <div style="padding: 15px; margin-top: 20px;" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
                <p class="info-size">Identity Information</p>
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Nationality</p>
                    <p>French</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Date of Birth</p>
                    <p>26-Feb-1964</p>
                  </div>
                </div>
                <div class="identity-info-line-two personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Country of Birth</p>
                    <p>France</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Department of Birth</p>
                    <p>034-Herault</p>
                  </div>
                </div>
                <div class="identity-info-line-three personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Place of Birth</p>
                    <p>SETE</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Birth Name</p>
                    <p>MATEOS</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="applicant-address-information-struct">

            <div class="ac-arrow-struct" @click="showHideElement(this, applicantPartShowing.addressInformationShowing, 'showHideAddressInformation')">
              <svg id="Layer_1" class="ac-arrow" :class="{ 'ac-arrow-active' : applicantPartShowing.addressInformationShowing, 'ac-arrow-not-active' : !applicantPartShowing.addressInformationShowing, 'ac-arrow-style-one': styles.styleOne, 'ac-arrow-style-two': styles.styleTwo}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 142.58"><path class="cls-1" d="M283.46,0c-48.14,48.45-96.56,97.17-141.68,142.58C97.21,97.75,48.06,48.32,0,0H283.46Z"/></svg>
              <p class="info-size info-size-title">Address Information</p>
            </div>

            <div class="subcontent-info applicant-info applicant-address-information" ref="addressInformationContent">
              <div style="padding: 15px; margin-top: 20px;" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
                <p class="info-size">Address Information</p>
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Street Number</p>
                    <p>41</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Street Name</p>
                    <p>AVENIDA MONSENOR RIBERI</p>
                  </div>
                </div>
                <div class="identity-info-line-two personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Postal Code</p>
                    <p>48230</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">City</p>
                    <p>CORIA</p>
                  </div>
                </div>
                <div class="identity-info-line-three personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Country</p>
                    <p>FRANCE</p>
                  </div>
                </div>
              </div>
              <div style="padding: 15px; margin-top: 20px;" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
                <p class="mention">Do you Rent or Own ?</p>
                <p class="info-size">Own</p>
              </div>
            </div>

          </div>

          <div class="applicant-financial-information-struct">

            <div class="ac-arrow-struct" @click="showHideElement(this, applicantPartShowing.employerAndFinancialInformationShowing, 'showHideFinancialInformation')">
              <svg id="Layer_1" class="ac-arrow" :class="{ 'ac-arrow-active' : applicantPartShowing.employerAndFinancialInformationShowing, 'ac-arrow-not-active' : !applicantPartShowing.employerAndFinancialInformationShowing, 'ac-arrow-style-one': styles.styleOne, 'ac-arrow-style-two': styles.styleTwo}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 142.58"><path class="cls-1" d="M283.46,0c-48.14,48.45-96.56,97.17-141.68,142.58C97.21,97.75,48.06,48.32,0,0H283.46Z"/></svg>
              <p class="info-size info-size-title">Employer And Financial Information</p>
            </div>

            <div class="subcontent-info applicant-info applicant-financial-information" ref="financialInformationContent">
              <div style="padding: 15px; margin-top: 20px;" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
                <p class="info-size">Employer Information</p>
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Employment Status</p>
                    <p>Employee</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Occupation Description</p>
                    <p>Commercial employee</p>
                  </div>
                </div>
                <div class="identity-info-line-two personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Company Name</p>
                    <p>GRAND NORD</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Industry</p>
                    <p>AY</p>
                  </div>
                </div>
                <div class="identity-info-line-three personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Employment Duration</p>
                    <p>10+ Years</p>
                  </div>
                </div>
              </div>
              <div style="padding: 15px; margin-top: 20px;" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
                <p class="info-size">Financial Information</p>
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Primary Income(€)</p>
                    <p>82,000.00</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Additional Income(€)</p>
                    <p>25,000.00</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Total Income(€)</p>
                    <p>107,000.00</p>
                  </div>
                </div>
                <div class="identity-info-line-two personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Verified Income(€)</p>
                    <p>81,679.56</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Verified Income Source</p>
                    <p>Tax Statement</p>
                  </div>
                </div>
                <div class="identity-info-line-three personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Other Source of Funds</p>
                    <p class="mention">No items</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Other Source Income(€)</p>

                  </div>
                </div>
                <div class="identity-info-line-four personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Estimated Wealth</p>
                    <p>&#62;100K-200K</p>
                  </div>
                </div>
              </div>
              <div style="padding: 15px; margin-top: 20px;" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
                <p class="info-size">Bank Details</p>
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Domiciliation - Code BIC</p>
                    <p>CREGBEBB</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Internation Account Number (IBAN)</p>
                    <p>BE837383930398383XX874</p>
                  </div>
                </div>
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Tenure with the Bank</p>
                    <p>From 10 years to 15 years</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="applicant-other-information-struct">

            <div class="ac-arrow-struct" @click="showHideElement(this, applicantPartShowing.otherInformationShowing, 'showHideOtherInformation')">
              <svg id="Layer_1" class="ac-arrow" :class="{ 'ac-arrow-active' : applicantPartShowing.otherInformationShowing, 'ac-arrow-not-active' : !applicantPartShowing.otherInformationShowing, 'ac-arrow-style-one': styles.styleOne, 'ac-arrow-style-two': styles.styleTwo}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 142.58"><path class="cls-1" d="M283.46,0c-48.14,48.45-96.56,97.17-141.68,142.58C97.21,97.75,48.06,48.32,0,0H283.46Z"/></svg>
              <p class="info-size info-size-title">Other Information</p>
            </div>

            <div class="subcontent-info applicant-info applicant-other-information" ref="otherInformationContent">
              <div style="padding: 15px; margin-top: 20px;" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
                <p class="info-size">Other Information</p>
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Signature</p>
                    <p>Yes</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Billing Cycle</p>
                    <p>____</p>
                  </div>
                  <div class="sub-content-struct">
                    <p class="sub-content-label">IP Address</p>
                    <p>80.13.50.163</p>
                  </div>
                </div>
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">FaceMatchingStatus</p>
                    <p>____</p>
                  </div>
                </div>
              </div>
              <div style="padding: 15px; margin-top: 20px;" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
                <p class="info-size">Global Card Relationship</p>
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Consent Provided</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="applicant-special-information-struct">

            <div class="ac-arrow-struct" @click="showHideElement(this, applicantPartShowing.specialHandlingInformationShowing, 'showHideSpecialInformation')">
              <svg id="Layer_1" class="ac-arrow" :class="{ 'ac-arrow-active' : applicantPartShowing.specialHandlingInformationShowing, 'ac-arrow-not-active' : !applicantPartShowing.specialHandlingInformationShowing, 'ac-arrow-style-one': styles.styleOne, 'ac-arrow-style-two': styles.styleTwo}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 142.58"><path class="cls-1" d="M283.46,0c-48.14,48.45-96.56,97.17-141.68,142.58C97.21,97.75,48.06,48.32,0,0H283.46Z"/></svg>
              <p class="info-size info-size-title">Special Handling Information</p>
            </div>

            <div class="subcontent-info applicant-info applicant-special-information" ref="specialInformationContent">
              <div style="padding: 15px; margin-top: 20px;" :class="{ 'subcontent-info-style-one' : styles.styleOne, 'subcontent-info-style-two' : styles.styleTwo}">
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">External Sales Agency</p>
                    <p>____</p>
                  </div>
                </div>
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Fee Code</p>
                    <p>Send Card / Send Bill</p>
                  </div>
                </div>
                <div class="identity-info-line-one personal-info-line">
                  <div class="sub-content-struct">
                    <p class="sub-content-label">Special Handling Indicator</p>
                    <p>____</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="content-information" ref="creditContent" v-show="catShowing.creditShowing">
          <div class="cond-group">
            <p class="info-size">Applicant</p>
            <DropdownSelect :current-option="currentOptionActive" :options-available="optionsAvailable" @resize="resizeSection('credit')" @update-selected-value="updateSelectedValue"/>
            <p class="info-size">Credit Bureau</p>
            <DropdownSelect :current-option="currentCreditBureauOptionActive" :options-available="creditBureauOptionsAvailable" @resize="resizeSection('credit')" @update-selected-value="updateCreditBureauSelectedValue"/>
          </div>
        </div>

        <div class="content-information" ref="suspenseChecksContent" v-show="catShowing.suspenseChecksShowing">
          <p>Suspense Checks</p>
        </div>

        <div class="content-information" ref="cboContent" v-show="catShowing.CBOShowing">
          <p>CBO</p>
        </div>

        <div class="content-information" ref="notesContent" v-show="catShowing.notesShowing">
          <p>Notes</p>
        </div>

        <div class="content-information" ref="communicationContent" v-show="catShowing.communicationsShowing">
          <p>Communications</p>
        </div>

        <div class="content-information" ref="referenceDataContent" v-show="catShowing.referenceDataShowing">
          <p>Reference Data</p>
        </div>

        <div class="content-information" ref="userTrackingNotesContent" v-show="catShowing.userTrackingNotesShowing">
          <p>User Tracking Notes</p>
        </div>

      </div>

    </div>

    <NotesEditorModule :modalActive="noteEditorActive" @close="closeNoteEditor">

      <p class="info-size" style="margin: 10px; font-weight: bold">AddNote</p>

      <div class="divider"></div>

      <div class="note-surface">

        <p class="info-size">New note</p>

        <textarea class="notes-field" :maxlength="noteCharactersRemaining" v-model="currentNote"></textarea>

        <p class="mention">Remaining {{noteCharactersRemaining - currentNote.length}} characters</p>

        <div class="note-editor-control">
          <div class="light-top-button" :class="{ 'light-top-button-style-one' : styles.styleOne, 'light-top-button-style-two' : styles.styleTwo}" @click="closeNoteEditor">
            <p>Cancel</p>
          </div>
          <div class="dark-top-button">
            <p>Save Note</p>
          </div>
        </div>

      </div>

    </NotesEditorModule>

  </div>
</template>

<script>
import {applySettings} from "@/settings/settings";
import { ref } from 'vue';
import NotesEditorModule from "@/components/NotesEditorModule";
import DropdownSelect from "@/components/DropdownSelect";


export default {
  components: {

    NotesEditorModule,
    DropdownSelect

  },


  setup(){

    const { styles } = applySettings();

    const productInformationContent = ref(null);
    const personalInformationContent = ref(null);
    const addressInformationContent = ref(null);
    const financialInformationContent = ref(null);
    const otherInformationContent = ref(null);
    const specialInformationContent = ref(null);

    const contentSection = ref(null);

    const overviewContent = ref(null);
    const applicantContent = ref(null);
    const creditContent = ref(null);
    const suspenseChecksContent = ref(null);
    const cboContent = ref(null);
    const notesContent = ref(null);
    const communicationsContent = ref(null);
    const referenceDataContent = ref(null);
    const userTrackingNotesContent = ref(null);

    const noteEditorActive = ref(false);
    const noteCharactersRemaining = ref(245);
    const currentNote = ref("");

    const premenuShowing = ref(false);
    const remaingOptionsShowing = ref(false);

    const optionsAvailable = ref({
      optionOne: {optionLine: 'Current Applicant', active: false},
      optionTwo: {optionLine: 'Additional Applicant', active: false},
      // optionThree: {optionLine: 'Referral Info', active: false},
      // optionFour: {optionLine: 'More', active: false}
    })

    const creditOptionsAvailable = ref({

    })

    const updateSelectedValue = (index) => {

      Object.keys(optionsAvailable.value).forEach(entry => {

        optionsAvailable.value[entry].active = false;

      });

      optionsAvailable.value[index].active = true;
      currentOptionActive.value = optionsAvailable.value[index].optionLine;

    }

    const currentOptionActive = ref("Select an option");
    const currentCreditBureauOptionActive = ref("Select an option");

    const updateCreditBureauSelectedValue = (index) => {

      Object.keys(optionsAvailable.value).forEach(entry => {

        creditOptionsAvailable.value[entry].active = false;

      });

      creditOptionsAvailable.value[index].active = true;
      currentCreditBureauOptionActive.value = creditOptionsAvailable.value[index].optionLine;

    }

    // const overviewShowing =  ref(true);
    // const applicantShowing =  ref(true);
    // const creditShowing =  ref(true);
    // const suspenseChecksShowing =  ref(true);
    // const CBOShowing =  ref(true);
    // const notesShowing =  ref(true);
    // const communicationsShowing =  ref(true);
    // const referenceDataShowing =  ref(true);
    // const userTrackingNotesShowing =  ref(true);

    const catShowing = ref({
      overviewShowing: true,
      applicantShowing: false,
      creditShowing: false,
      suspenseChecksShowing: false,
      CBOShowing: false,
      notesShowing: false,
      communicationsShowing: false,
      referenceDataShowing: false,
      userTrackingNotesShowing: false
    })

    const applicantPartShowing = ref({
      productInformationShowing: false,
      personalInformationShowing: false,
      addressInformationShowing: false,
      employerAndFinancialInformationShowing: false,
      otherInformationShowing: false,
      specialHandlingInformationShowing: false,
    })

    const showPreMenu = () => {

      premenuShowing.value = !premenuShowing.value;

    }

    const showRemainingOptions = () => {

      remaingOptionsShowing.value = true;

    }

    const hideRemainingOptions = () => {

      remaingOptionsShowing.value = false;

    }

    const showNoteEditor = () => {

      if(!noteEditorActive.value){
        noteEditorActive.value = true;
      }

    }

    const closeNoteEditor = () => {

      if(noteEditorActive.value){
        noteEditorActive.value = false;
        currentNote.value = "";
      }

    }

    const collapseSection = (element) => {
      // get the height of the element's inner content, regardless of its actual size
      const sectionHeight = element.scrollHeight;

      // temporarily disable all css transitions
      const elementTransition = element.style.transition;
      element.style.transition = '';

      // on the next frame (as soon as the previous style change has taken effect),
      // explicitly set the element's height to its current pixel height, so we
      // aren't transitioning out of 'auto'
      requestAnimationFrame(function() {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;

        // on the next frame (as soon as the previous style change has taken effect),
        // have the element transition to height: 0
        requestAnimationFrame(function() {
          element.style.height = 0 + 'px';
          element.style.opacity = 0;
          element.style.padding = 0 + 'px';
        });
      });

      // mark the section as "currently collapsed"
      // element.setAttribute('data-collapsed', 'true');
    }

    const recalculateHeight = (element, newElement) => {


      delay(500).then(() => {

        // get the height of the element's inner content, regardless of its actual size
        const sectionHeight = newElement.scrollHeight;

        console.log(sectionHeight);

        element.style.height = sectionHeight + 'px';

      });

    }

    function delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    }

    const expandSection = (element) => {
      // get the height of the element's inner content, regardless of its actual size
      const sectionHeight = element.scrollHeight;

      // have the element transition to the height of its inner content
      element.style.height = sectionHeight + 'px';

      element.style.opacity = 1;

      element.style.padding = 15 + 'px';

      // when the next css transition finishes (which should be the one we just triggered)
      element.addEventListener('transitionend', function(e) {
        // remove this event listener so it only gets triggered once
        console.log(e);
        element.removeEventListener('transitionend', arguments.callee);

        // remove "height" from the element's inline styles, so it can return to its initial value
        element.style.height = null;
      });

      // mark the section as "currently not collapsed"
      // element.setAttribute('data-collapsed', 'false');
    }

    const resizeSection = (section) => {

      if(section === 'overview'){

        recalculateHeight(contentSection.value, overviewContent.value)

      } else if(section === 'credit'){

        recalculateHeight(contentSection.value, creditContent.value)

      }



    }

    const showHideElement = (element, cat, action) => {

      if(action === "showHideProductInformation") {
        applicantPartShowing.value.productInformationShowing = !cat
        if(applicantPartShowing.value.productInformationShowing){
          expandSection(productInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        } else {
          collapseSection(productInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        }
      } else if (action === "showHidePersonalInformation"){
        applicantPartShowing.value.personalInformationShowing = !cat
        if(applicantPartShowing.value.personalInformationShowing){
          expandSection(personalInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        } else {
          collapseSection(personalInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        }
      } else if (action  === "showHideAddressInformation"){
        applicantPartShowing.value.addressInformationShowing = !cat
        if(applicantPartShowing.value.addressInformationShowing){
          expandSection(addressInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        } else {
          collapseSection(addressInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        }
      } else if (action === "showHideFinancialInformation"){
        applicantPartShowing.value.employerAndFinancialInformationShowing = !cat
        if(applicantPartShowing.value.employerAndFinancialInformationShowing){
          expandSection(financialInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        } else {
          collapseSection(financialInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        }
      } else if (action === "showHideSpecialInformation"){
        applicantPartShowing.value.specialHandlingInformationShowing = !cat
        if(applicantPartShowing.value.specialHandlingInformationShowing){
          expandSection(specialInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        } else {
          collapseSection(specialInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        }
      } else if (action === "showHideOtherInformation"){
        applicantPartShowing.value.otherInformationShowing = !cat
        if(applicantPartShowing.value.otherInformationShowing){
          expandSection(otherInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        } else {
          collapseSection(otherInformationContent.value)
          recalculateHeight(contentSection.value, applicantContent.value)
        }
      }

    }

    const changeCategoryShowing = (name) => {

      Object.keys(catShowing.value).forEach(entry => {
        // const [key, value] = entry;
        // console.log(key, value);
        catShowing.value[entry] = false;
        // entry.values = false;

      });

      if(name === 'showOverview'){
        catShowing.value.overviewShowing = true;
        recalculateHeight(contentSection.value, overviewContent.value)
      } else if(name === 'showApplicant'){
        catShowing.value.applicantShowing = true;
        recalculateHeight(contentSection.value, applicantContent.value)
      } else if(name === 'showCredit'){
        catShowing.value.creditShowing = true;
        recalculateHeight(contentSection.value, creditContent.value)
      } else if(name === 'showSuspenseCheck'){
        catShowing.value.suspenseChecksShowing = true;
        recalculateHeight(contentSection.value, suspenseChecksContent.value)
      } else if(name === 'showCBO'){
        catShowing.value.CBOShowing = true;
        recalculateHeight(contentSection.value, cboContent.value)
      } else if(name === 'showNotes'){
        catShowing.value.notesShowing = true;
        recalculateHeight(contentSection.value, notesContent.value)
      } else if(name === 'showCommunications'){
        catShowing.value.communicationsShowing = true;
        recalculateHeight(contentSection.value, communicationsContent.value)
      } else if(name === 'showReferenceData'){
        catShowing.value.referenceDataShowing = true;
        recalculateHeight(contentSection.value, referenceDataContent.value)
      } else if(name === 'showUserTrackingNotes'){
        catShowing.value.userTrackingNotesShowing = true;
        recalculateHeight(contentSection.value, userTrackingNotesContent.value)
      }



    }

    return { // make it available in <template>
      styles,
      productInformationContent,
      personalInformationContent,
      addressInformationContent,
      financialInformationContent,
      otherInformationContent,
      specialInformationContent,
      contentSection,

      overviewContent,
      applicantContent,
      creditContent,
      suspenseChecksContent,
      cboContent,
      notesContent,
      communicationsContent,
      referenceDataContent,
      userTrackingNotesContent,

      premenuShowing,
      showPreMenu,

      optionsAvailable,
      creditOptionsAvailable,
      currentOptionActive,
      currentCreditBureauOptionActive,

      remaingOptionsShowing,
      showRemainingOptions,
      hideRemainingOptions,

      catShowing,
      applicantPartShowing,
      collapseSection,
      expandSection,
      showHideElement,
      resizeSection,
      updateSelectedValue,
      updateCreditBureauSelectedValue,
      changeCategoryShowing,

      noteEditorActive,
      noteCharactersRemaining,
      currentNote,
      showNoteEditor,
      closeNoteEditor
    }

  },
}

</script>

<style>

.app{
  margin-bottom: 100px;
  height: auto;
  transition: all 0.6s ease-in-out;
}

.cm-title{
  display: flex;
  align-items: center;
  gap: 5px;
}

.cm-title p, .urgency-mention p {
  font-size: 14px;
}

.title-struct{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.divider{
  height: 1px;
  width: 100%;
  background-color: #abb9bd;
}

.header-info{
  display: flex;
  padding-top: 10px;
}

.header-info-part{
  padding: 0 15px;
}

.third{
  min-width: 25%;
}

.fourth{
  min-width: 18%;
}

.mention {
  font-size: 12px;
  margin: 0;
}

.card-image{
  max-width: 180px;
}

.mini-divider{
  height: 2px;
  width: 30px;
  background-color: #abb9bd;
  margin-top: 10px;
}

.info-size{
  font-size: 14px;
  margin: 0;
}

.second{
  padding-left: 30px;
}

.struct-with-line{
  border-left: 1px solid #abb9bd;
  height: 60px;
}

.phone-cat{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-bar{
  margin-top: 60px;
  display: flex;
  gap: 30px;
}

.progress-step-wrapper{
  height: 30px;
  width: 120px;
  position: relative
}

.progress-step-offset{
  margin-left: -6px  !important;
}

.progress-step{
  position: absolute;
  top: 0;
  left: 0;
  height:30px;
  width: 150px;
}

.progress-step-style-one polygon{
  fill:#888888;
}

.progress-step-style-two polygon{
  fill:#3a6a89;
}

.progress-step-text{
  z-index: 0;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  height: 30px;
  width: 120px;
  display: flex;
  justify-content: left;
  margin-left: 20px;
  align-items: center;
}

.progress-step-text p {
  margin: 0 10px 0 0;
  font-weight: bold;
}

.status-bar{
  margin-top: 15px;
  position: relative;
}

.status-bar-text{
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 0;
}

.top-button-section{
  margin-top: 10px;
  width: 100%;
  border: 1px solid #abb9bd;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: right;
}

.top-buttons{
  display: flex;
  margin-right: 20px;
  gap: 5px;
  align-items: center;
  position: relative;
}

.light-top-button{
  background-color: #ccdbdd;
  color: white;
  font-weight: bold;
  height: 20px;
  display: flex;
  align-items: center;
  border: 1px solid #83bcc5;
  border-radius: 2px;
  width: 80px;
  justify-content: center;
  cursor: pointer;
}

.dark-top-button{
  height: 22.3px;
  background-color: #0094c4;
  font-weight: bold;
  display: flex;
  align-items: center;
  border-radius: 2px;
  width: 80px;
  justify-content: center;
  cursor: pointer;
}

.light-top-button-style-one{
  background-color: #83bcc5;
}

.light-top-button-style-two{
  background-color: #3a6a89;
}

.light-top-button p, .dark-top-button p{
  font-size: 12px;
}

.dark-top-button p{
  color: white;
}

.tab-control{
  padding: 10px;
  width: max-content;
  height: max-content;
  cursor: pointer;
}

.tab-control p{
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}

.tab-active{
  background-color: transparent;
  border: 1px solid #abb9bd;
}

.tab-active-style-one{
  border-bottom: 1px solid #efefef;
}

.tab-active-style-two{
  border-bottom: 1px solid #00175a;
}

.tab-inactive{
  background-color: #3a6a89;
  border: 1px solid #abb9bd;
}

.tab-inactive p {
  color: white;
}

.main-app-content{
  margin-top: 15px;
}

.control-section{
  display: flex;
  gap: 5px;
}

.content-section{

  border: 1px solid #abb9bd;
  min-height: 100px;
  margin-top: -1px;
  transition: all 0.6s ease-in-out;
  overflow: hidden;

}

.content-information{
  padding: 10px;
}

.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.8s;
  opacity: 1;
  /*max-height: 430px;*/
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
  transition: all 0.4s;
  opacity: 0;
  /*height: 0;*/
  /*max-height: 0;*/
}

.content-info-parts{
  width: 100%;
  grid-area: main;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(7, 1fr);;
  grid-gap: 10px;
}

.overview-status{
  grid-column: 1 / 5;
  grid-row: 1 / 3;
  min-height: 100px;
}

.overview-app-details{
  grid-column: 1 / 3;
  grid-row: 3 / 6;
}

.overview-relevant-dates{
  grid-column: 3 / 5;
  grid-row: 3 / 7;
}

.overview-cts{
  grid-column: 1 / 3;
  grid-row: 6 / 8;
}

.subcontent-info{
  /*min-height: 50px;*/
  padding: 10px;
}

.subcontent-info-style-one{
  background-color: #ffffff;
}

.subcontent-info-style-two{
  border: 0.5px solid white
}

.ac-arrow-struct{
  display: flex;
  justify-content: left;
  margin: 10px;
  cursor: pointer;
  z-index: 2;
}

.ac-arrow{
  max-width: 15px;
  rotate: 0deg;
  transition: all  0.3s ease-in-out;
}

.ac-arrow-style-one{
  fill: #2d597f;
}

.ac-arrow-style-two{
  fill: #ffffff;
}

.ac-arrow-not-active{
  rotate: -90deg;
  transition: all  0.2s ease-in-out;
}

.ac-arrow-active{
  rotate: 0deg;
  transition: all  0.2s ease-in-out;
}

.applicant-info{
  margin-left: 25px;
  margin-right: 25px;
  min-height: 0;
  height: 0;
  opacity: 0;
  padding: 0;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
}

.note-surface{
  height: 170px;
  border: 1px solid;
  margin: 15px 10px 10px;
  padding: 10px 15px 10px 15PX;
}

.notes-field{
  width: -webkit-fill-available;
  margin-top: 5px;
  background: transparent;
  border: 1px solid white;
  height: 85px;
  color: white;
  font-family: system-ui;
  padding: 5px;
}

.note-editor-control{
  display: flex;
  justify-content: center;
  gap: 2px;
}

.action-premenu{
  background-color: #2d597f;
  width: 110px;
  position: absolute;
  top: 45px;
  right: 105px;
  border: 1px solid #83bcc5;
  border-radius: 5px;
  overflow: hidden;
}

.remaining-option-menu{
  background-color: #2d597f;
  width: 210px;
  position: absolute;
  top: 75px;
  right: 220px;
  border: 1px solid #83bcc5;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0 3px 30px rgb(247 246 246 / 30%);
}

.action-menu-option{
  padding: 5px 0;
  cursor: pointer;
}

.action-menu-option:hover{
  background: #013147;
}

.action-menu-title{
  margin: 0 0 0 10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.subcontent-info p {
  margin: 0;
  /*font-size: 14px;*/
}

.app-detail-info-struct{
  margin-top: 20px;
  display: grid;
  grid-row: auto;
  row-gap: 20px;
}

.grid-four{
  grid-template-columns: repeat(4, 1fr);
}

.grid-three{
  grid-template-columns: repeat(3, 1fr);
}

.sub-content-label{
  font-size: 10px !important;
}

.cond-group{
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.applicant-product-information{
  position: relative;
  margin-top: 0px;
  overflow: hidden;
}

.applicant-product-information-visible{
  margin-top: 70px;
  overflow: visible;
}

.outside-m{
  position: absolute;
  top: -50px;
  left: 0;
}

.product-info-line, .personal-info-line {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.applicant-personal-information, .applicant-address-information, .applicant-financial-information, .applicant-other-information, .applicant-special-information {
  padding: 0 !important;
}

.applicant-product-information-struct, .applicant-personal-information-struct, .applicant-address-information-struct, .applicant-financial-information-struct, .applicant-other-information-struct, .applicant-special-information-struct{
  margin-bottom: 20px;
}

</style>
