<template>
  <div class="page-not-found">
    <div class="page-not-found-layout">
      <h1>404<br>Page not found</h1>
      <a class="link-header button slide__button button--appear" @click="back()">
        <div class="button__content">
          <p style="transform: scaleX(1);">
            <span style="visibility: visible; opacity: 1;">Back</span>
          </p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import SupralataHeader from '@/components/SupralataHeader.vue'
// // import SupralataServices from '@/components/SupralataServices.vue'
// import SupralataFooter from '@/components/SupralataFooter.vue'
// import NavigationBar from '@/components/NavigationBar.vue'

export default {
  name: 'PageNotFound',
  components: {
    // SupralataHeader,
    // // SupralataServices,
    // SupralataFooter
    // NavigationBar
  },
  methods: {
    back(){
      this.$router.push({name: 'Home'});
    }
  }
}

</script>

<style scoped>

.page-not-found{
  background-color: #0b1149;
  color: white;
  font-family: 'Bilo', sans-serif;
  height: 100vh;
  position: relative;
}

.page-not-found-layout{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.page-not-found h1{
  font-size: 32px;
  margin: 0;
}

.link-header{
  display: block;
  width: fit-content;
  margin: 20px auto 0;
  cursor: pointer;
}

.link-header p{
  font-weight: bold;
  border: 1px solid white;
  background-color: transparent;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.link-header .button__content p::after {
  content: '';
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: white;
  transform-origin: bottom center;
  transform: scaleY(0);
  transition: transform .3s ease;
  will-change: transform;
  z-index: -1;
}

.link-header .button__content:hover p:after {
  transform: scaleY(1);
}

.link-header .button__content p span {
  color: white;
}

.link-header .button__content:hover p span {
  color: black;
  transition: all 0.4s ease-in-out;
}

</style>