<template>
  <div class="drop-down-menu">
    <div class="select" ref="selectButton" @click="showOptions">
      <span class="selected">{{currentOption}}</span>
      <div class="caret"></div>
    </div>
    <ul class="menu" :class="{'menu-open': optionsShowing}">
      <li v-for="(option, index) in optionsAvailable" :class="{'option-active': option.active}" :key="index" @click="updateSelectedValue(index)">
        {{option.optionLine}}
      </li>
<!--      <li :class="{'option-active': optionOne}">{{optionsAvailable.optionOne.optionLine}}</li>-->
<!--      <li :class="{'option-active': optionTwo}">{{optionsAvailable.optionTwo}}</li>-->
<!--      <li :class="{'option-active': optionThree}">{{optionsAvailable.optionThree}}</li>-->
<!--      <li :class="{'option-active': optionFour}">{{optionsAvailable.optionFour}}</li>-->
    </ul>
  </div>
</template>

<script>
import {getCurrentInstance, ref} from "vue";

export default {
  name: 'DropdownSelect',
  props: {
    currentOption: String,
    optionsAvailable: {}
  },
  setup(){

    const selectButton = ref(null);

    const optionsShowing = ref(false);

    const instance = getCurrentInstance();

    // const optionsAvailable = {
    //   optionOne: "Current Applicant",
    //   optionTwo: "Additional Applicant",
    //   optionThree: "Bonus Scheme",
    //   optionFour: "Refresh GO2"
    // };

    const updateSelectedValue = (index) => {

      instance.emit('update-selected-value', index);

      optionsShowing.value = false;

      instance.emit('resize');

    }

    const showOptions = () => {

      optionsShowing.value = !optionsShowing.value;

      // that.$emit('resize');

      instance.emit('resize');

    }

    // const currentOption = ref("");
    //
    // const changeSelectValue = (newValue) => {
    //
    //   currentOption.value = newValue;
    //
    //   //this.$emit('close');
    //
    // }
    //
    //
    return{

      // currentOption,
      // changeSelectValue
      selectButton,
      optionsShowing,
      updateSelectedValue,
      // optionsAvailable,
      showOptions

    }

  },
};
</script>

<style scoped>

.drop-down-menu{
  position: relative;
}

.drop-down-menu * {
  box-sizing: border-box;
}

.select{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-radius: 5px;
  padding: 2px 35px;
  cursor: pointer;
  transition: background 0.3s;
  gap: 10px;
}

.select-clicked{
  border: 2px solid;
  box-shadow: 0 0 0.8em white;
}

.selected:hover{
  background-color: #0b1149;
}

.caret{
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #fff;
  transition: 0.3s;
}

.caret-rotate{
  transform: rotate(180deg);
}

.menu{
  list-style: none;
  padding: 0.2em 0;
  background-color: #2c3e50;
  border-radius: 0.3em;
  position: absolute;
  /*top: 3em;*/
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: all 0.4s ease-in-out;
  z-index: 1;
  height: 50px;
  overflow-y: scroll;
}

.menu li {
  /*padding: 0.7em 0.5em;*/
  /*margin: 0.3em 0;*/
  /*border-radius: 0.5em;*/
  padding: 0.2em 0.5em;
  cursor: pointer;
}

.menu li:hover{
  background-color: #173b54;
}

.option-active{
  background-color: #122f43;
}

.menu-open{
  display: block;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

</style>